export default {
    install(Vue, options) {
        Vue.prototype.time = function (value) {
            var length = Math.floor(parseInt(value));
            var hour = Math.floor(value / 3600);
            var minute = Math.floor(value / 60);
            if (minute < 10) {
                minute = "0" + minute;
            }
            var second = length % 60;
            if (second < 10) {
                second = "0" + second;
            }
            if (hour > 0 && hour < 10) {
                minute = Math.floor((value - hour * 3600) / 60);
                hour = '0' + hour;
                if (minute < 10) {
                    minute = "0" + minute;
                }
                return hour + ':' + minute + ":" + second;
            } else {
                return minute + ":" + second;
            }
        }
        Vue.prototype.dateTime = function (date) {
            var y = date.getFullYear();
            var m = date.getMonth() + 1;
            m = m < 10 ? ('0' + m) : m;
            var d = date.getDate();
            d = d < 10 ? ('0' + d) : d;
            var h = date.getHours();
            h = h < 10 ? ('0' + h) : h;
            var minute = date.getMinutes();
            minute = minute < 10 ? ('0' + minute) : minute;
            var second = date.getSeconds();
            second = second < 10 ? ('0' + second) : second;
            return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second;
        }
        // 对象数组按照属性排序
        Vue.prototype.sortObjectArray = function (sortUp, arr, prop1, prop2) {
            let compare = function (prop1, prop2) {
                return function (obj1, obj2) {
                    let val1 = obj1[prop1];
                    let val2 = obj2[prop1];
                    // if (!isNaN(Number(val1)) && !isNaN(Number(val2))) {
                    //     val1 = Number(val1);
                    //     val2 = Number(val2);
                    // }
                    if (val1 < val2) {
                        return sortUp === 'des' ? 1 : -1;
                    } else if (val1 > val2) {
                        return sortUp === 'des' ? -1 : 1;
                    } else {
                        if (prop2) {
                            let val3 = obj1[prop2];
                            let val4 = obj2[prop2];
                            if (val3 < val4) {
                                return sortUp === 'des' ? 1 : -1;
                            } else if (val3 > val4) {
                                return sortUp === 'des' ? -1 : 1;
                            }
                        } else {
                            return 0;
                        }
                    }
                }
            }
            return arr.sort(compare(prop1, prop2));
        }
        //数字转换为千分位显示-ykf
        Vue.prototype.toThousands = function (num) {
            var result = [], counter = 0;
            num = (num || 0).toString().split('');
            for (var i = num.length - 1; i >= 0; i--) {
                counter++;
                result.unshift(num[i]);
                if (!(counter % 3) && i != 0) {
                    result.unshift(',');
                }
            }
            return result.join('');
        }
        Vue.prototype.comBack = function () {
            this.$router.back(-1);
        }
        // ---------xm
        Vue.prototype.isSuperAdmin = function () {//判断是不是超管
            // console.log('this.userInfo11 :>> ', this.userInfo);
            return  this.userInfo.merchant.type != 11 && this.userInfo.merchant_user.is_super_admin == 1
           
        }
        Vue.prototype.hasAuth = function (arr) {//判断没有权限
            if ( this.userInfo&&this.userInfo.permissions && this.userInfo.permissions.length) {
                let isHas = false;
                let contentSet = '';
                for (let i = 0; i < arr.length; i++) {
                    let index = this.userInfo.permissions.findIndex(ele => {
                        return arr[i] == ele.real_name;
                    });
                    if (index != -1) {
                        isHas = true;
                        break;
                    }
                }
                return isHas;
            } else {
                return false;
            }
        }
        
        // 动态判断权限
        Vue.prototype.hasPermission = function (permissions, arr) {//判断没有权限
            if ( permissions.length) {
                let isHas = false;
                for (let i = 0; i < arr.length; i++) {
                    let index = permissions.findIndex(ele => {
                        return arr[i] == ele.real_name;
                    });
                    if (index != -1) {
                        isHas = true;
                        break;
                    }
                }
                return isHas;
            } else {
                return false;
            }
        }
    }


}

/*
 * console.log - xm type:代表类别必传，1代表前端正常打印测试数据，该数据只显示在本地、review、staging环境，2打印报错日志本地、review、staging,production都将会打印
 * hint:打印提示语可传可不传，
 * data:需要打印的数据必传
 * */
export function getConSoleLog(type,hint,data) {
    console.log('process.env.VUE_APP_ENV',process.env.VUE_APP_ENV);
    if(type==1&&process.env.VUE_APP_ENV!='production'){

       if(hint&&data){
         console.log(`${hint}`,data)
       }else {
           console.log(hint)
       }
    }
    if(type==2){
        if(hint&&data){
            console.log(`${hint}`,data)
        }else {
            console.log(hint)
        }
    }
}
