import {cookier} from '@/util/index';

// 用户
const state = {
  userInfo: {}
};

const getters = {
  userInfo: state => state.userInfo,
};

const mutations = {
  setUserInfo(state, {data}) {
    state.userInfo = Object.assign(state.userInfo, data);
  },
  clear(state) {
    state.userInfo = {};
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations
}
