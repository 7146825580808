// 请求
import axios from 'axios';
import {Message} from 'element-ui';
import {resInterceptors, reqInterceptors} from './http-interceptors';
import {getConSoleLog} from "./util";

// // console.log('process.env.NODE_ENV', process.env.NODE_ENV);
let API_BASE = process.env.VUE_APP_API_BASE || '/api';

// 乾坤开发环境
if (process.env.NODE_ENV === 'development' && window.__POWERED_BY_QIANKUN__) {
    API_BASE = "/api-cms"
  }
  

// 创建axios实例，添加设置
const mAxios = axios.create({
  baseURL: API_BASE,
  // baseURL: 'http://api-cms.review.slradio.cn',
  headers: {'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Accept': 'application/json'},
  timeout: 40000
  // withCredentials: true
});

// http request 拦截
mAxios.interceptors.request.use(config => {
  return reqInterceptors(config);
}, error => {
  return Promise.reject(error);
});
// http respone 拦截
mAxios.interceptors.response.use(
  response => {
    // 拦截器
    return resInterceptors(response);
  },
  error => {
    if (error.response) {

      switch (error.response && error.response.status) {
        case 403:
          // Message.error('无权限请求该资源！');
          console.error('无权限请求该资源！')
          break;
        case 404:
          // Message.error('请求地址未找到！');
          console.error('请求地址未找到！')
          break;
        case 405:
          console.error('请求地址未找到！')
          // Message.error('请求方式错误！');
          break;
        case 429:
          console.error('请求次数过多，请稍后重试！')
          // Message.error('请求次数过多，请稍后重试！');
          break;
        case 500:
          console.error('服务器错误，请稍后重试')
          // Message.error('服务器错误，请稍后重试');
          break;
        case 502:
          console.error('服务器暂不可用，请稍后重试！')
          // Message.error('服务器暂不可用，请稍后重试！');
          break;
        case 503:
          // Message.error('网关错误！');
          console.error('网关错误！')
          break;
        case 200:
          //xhr.success(JSON.parse(xhr.responseText));
          break;
        case 0:
          // 0可能是网络错误? 可能是连接超时
          if (error.code === 'ECONNABORTED') {
            // 超时
            // Message.error('请求超时，请稍后重试');
            console.error('请求超时，请稍后重试')

          } else if (error.this.$message && error.this.$message === 'cancel') {
            // 取消请求，不做处理
          } else {
            // // // console.log(error);
            // 其他未知错误，通常为无网络
            // Message.error('请求资源失败，请检查网络状况，稍后重试');
            console.error('请求资源失败，请检查网络状况，稍后重试')
          }
          break;
        default:
          console.log(error)
          // 0可能是网络错误? 可能是连接超时
          if (error.code === 'ECONNABORTED') {
            // 超时
            // Message.error('请求超时，请稍后重试');
            console.error('请求超时，请稍后重试')
          } else if (error.this.$message && error.this.$message === 'cancel') {
            // 取消请求，不做处理
          } else {
            // // // console.log(error);
            // 其他未知错误，通常为无网络
            // Message.error('请求资源失败，请检查网络状况，稍后重试');
            console.error('请求资源失败，请检查网络状况，稍后重试')
          }
      }
    } else {
      // Message.error('请求资源失败，请检查网络状况，稍后重试');
      console.error('请求资源失败，请检查网络状况，稍后重试')
    }
    return Promise.reject(error);
  }
);

export default mAxios;
