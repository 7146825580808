import Vue from "vue";
import App from "./App.vue";
// vuex
import store from "./store/index";

// import router from "./router/index";
// ui组件
import ElementUI from "element-ui";
// ui组件样式
import "element-ui/lib/theme-chalk/index.css";
import "./common/style/globe.scss"; // 全局
import "./common/style/layout.scss"; // 布局
import "./common/style/common.scss"; // 公共
import multipleSelect from "./components/select-vue-component";
import util from "./util/util";
//v-chart
import VeLine from "v-charts/lib/line.common";
Vue.component(VeLine.name, VeLine);
// axios
import vueAxios from "vue-axios";
import mAxios from "./util/axios";
import VueClipboard from "vue-clipboard2";
import actions from './action'
import './public-path';

Vue.use(VueClipboard);
Vue.use(ElementUI);
Vue.component("multipleSelect", multipleSelect);
Vue.use(vueAxios, mAxios);

import "ant-design-vue/dist/antd.css";
import { Select } from "ant-design-vue";
/* v1.1.2 */
Vue.use(Select);

// 图片懒加载
// 懒加载
import VueLazy from "vue-lazyload";
import placeImg from "./assets/images/audit-manage/ic_place.png";
Vue.use(VueLazy, {
  loading: placeImg,
  error: placeImg,
});

Vue.use(ElementUI);
Vue.component("multipleSelect", multipleSelect);
Vue.use(vueAxios, mAxios);
Vue.use(util);

Vue.config.productionTip = false;
console.log = (function (oriLogFunc) {
  return function () {
    //判断配置文件是否开启日志调试
    if (process.env.VUE_APP_ENV !== 'production') {
      try{
        oriLogFunc.call(console, ...arguments);
      }catch(e){
        console.error('console.log error', e);
      }
    }
  }
})(console.log);

// let oldLogHandler = console.log;
// console.log = function() {
//   if (process.env.NODE_ENV !== 'development') {
//       oldLogHandler(...arguments);
//   }
// }
// console.log('11111','2222222')

let instance = null;
let router = null;

function render(props = {}) {
  if (props) {
    // 注入 actions 实例
    actions.setActions(props);
  }
  const {
    container
  } = props;
  // console.log('props :>> ', props);
  router = require('./router/index').default
  // 此处引用router后，删除之前的引用
  if (window.__POWERED_BY_QIANKUN__) {
    router.options.base = props.base;
    router.history.base = props.base;
  }

  // 注意：此处覆盖原有的 new vue().mount方法
  instance = new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount(container ? container.querySelector('#app') : '#app');
}

Vue.prototype.__POWERED_BY_QIANKUN__ = window.__POWERED_BY_QIANKUN__
if (!window.__POWERED_BY_QIANKUN__) {
  render();
}

export async function bootstrap() {
  // alert('boot cms')
  console.log('[vue] vue app bootstraped');
}
export async function mount(props) {
  console.log('[vue] props from main framework aaa', props);
  render(props);

}
export async function unmount() {
  ElementUI.Message.closeAll();
  instance.$destroy();
  instance.$el.innerHTML = '';
  instance = null;
  router = null;
}


