<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { getOssSetting } from "./api/upload";
import { getVoiceStatus } from "./api/common";
import { mapMutations } from "vuex";

export default {
  data() {
    return {
      initOss: false,

    };
  },
  methods: {
    ...mapMutations("upload", ["setOssUploadInfo"]),
    ...mapMutations("baseInfo", ["setVoiceStatus"]),
    // getOss() {
    //   getOssSetting({ module: "logo" })
    //     .then((res) => {
    //       // 保存到vuex
    //       if (res.code == "100000") {
    //         this.setOssUploadInfo({ info: res.data });
    //         this.initOss = true;
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
    // 语音播报配置
    getVoiceStatus() {
      getVoiceStatus()
        .then((res) => {
          if (res.code == 100000) {
            this.setVoiceStatus(res.data.status);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    // if (!this.initOss) {
    //   this.getOss();
    // }
    this.getVoiceStatus();
  },
};
</script>
