// 用户
const state = {
  ossInfo: null
};

const mutations = {
  setOssUploadInfo(state, {info}) {
    state.ossInfo = info;
  },
  clear(state) {
    this.state.ossInfo = null
  }
};

export default {
  namespaced: true,
  state,
  mutations
}
