const state = {
    currentTime: 0,
    durationTime: 100,
    playing: false,
    change: false, // 判断是更改的时间还是播放的时间,
    loading: false, // 是否正在加载中,
    bufferedTime: 0,
    loading: false, // 是否正在加载中,
    tmpCurrentTime: 0,
}
const getters = {
    currentTime: state => state.currentTime,
    // playing: state => state.playing,
    durationTime: state => state.durationTime,
    tmpCurrentTime: state => state.tmpCurrentTime,
    loading: state => state.loading,
    change: state => state.change,
    prCurrentTime: state => {
        return state.currentTime / state.durationTime * 100
    },
    prBufferedTime: state => {
        return state.bufferedTime / state.durationTime * 100
    },
}
const mutations = {
    canplay(state, payload) {
        state.playing = payload
    },
    updateBufferedTime(state, time) {
        state.bufferedTime = time
    },
    updateDurationTime(state, time) {
        state.durationTime = time
    },
    setChange(state, flag) {
        state.change = flag
    },
    updateCurrentTime(state, time) {
        state.currentTime = time
    },
    closeLoading(state) {
        state.loading = false
    },
    changeTime(state, time) {
        state.tmpCurrentTime = time
    },
}
export default {
    // namespaced: true,
    state,
    getters,
    mutations
}
