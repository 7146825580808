const wishAudit = () => import('@/views/civilization/wish-audit');
const orderAudit = () => import('@/views/civilization/order-audit');
const serveAudit = () => import('@/views/civilization/serve-audit');
const activityAudit = () => import('@/views/civilization/activity-audit');
const orderManage = () => import('@/views/civilization/order-manage');
const teamManage = () => import('@/views/civilization/team-manage');
const volunteerManage = () => import('@/views/civilization/volunteer-manage');


export default [
    {
      path: '/wish-audit',
      name: '微心愿审核',
      component: wishAudit,
      meta: {
        id: 'wxysh'
      }
    },
    {
      path: '/order-audit',
      name: '点单审核',
      component: orderAudit,
      meta: {
        id: 'ddsh'
      }
    },
    {
      path: '/serve-audit',
      name: '服务审核',
      component: serveAudit,
      meta: {
        id: 'fwsh'
      }
    },
    {
      path: '/activity-audit',
      name: '活动审核',
      component: activityAudit,
      meta: {
        id: 'hdsh'
      }
    },
    {
      path: '/order-manage',
      name: '点单服务管理',
      component: orderManage,
      meta: {
        id: 'ddfwgl'
      }
    },
    {
      path: '/team-manage',
      name: '团队管理',
      component: teamManage,
      meta: {
        id: 'tdgl'
      }
    },
    {
      path: '/volunteer-manage',
      name: '志愿者管理',
      component: volunteerManage,
      meta: {
        id: 'zyzgl'
      }
    },
]