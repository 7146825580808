import Vue from 'vue';
import Vuex from 'vuex';
import menu from './modules/menu';
import upload from './modules/upload';
import baseInfo from './modules/baseInfo';
import audioPlay from './modules/audioPlay';
import user from './modules/user';
import comment from './modules/comment'
import {cookier} from "../util";

Vue.use(Vuex);

const state = {};


const mutations = {
  logout(state, {focus = false}) {
    localStorage.removeItem('userInfo');
    localStorage.setItem('needLogout', 1);
    cookier.del('Authorization');
    // console.log(url + '/login');
    const logoutPath = cookier.get('logoutPath');
    if (window.location.href !== logoutPath) {
      let path = logoutPath;
      if (focus) {
        path += '?_n=1';
        window.location.href = path + '&_f=' + encodeURIComponent(window.location.href);
      } else {
        window.location.href = path + '?_f=' + encodeURIComponent(window.location.href);
      }
      // alert(path)
      // window.location.href = 'http://' + url + '/login?f=';
    }
    // window.open(url + '/login')
    // window.location.href = url + '/login';
  }
};

export default new Vuex.Store({
  state,
  mutations,
  modules: {
    menu,
    upload,
    baseInfo,
    audioPlay,
    user,
    comment
  }
})
