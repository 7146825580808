import request from '@/util/axios';

/**
 * @params 基础信息接口
 */
// 获取基础信息
export function getBaseInfo(params) {
    return request({
        url:'/v1/basicInfo',
        method:'get',
        params
    })
}

export function getVoiceStatus(params) {
  return request({
    url:'/v2/conversion_voice_configs',
    method:'get',
    params
  })
}

// B端-文明实践增值服务查询
export function getAppModule(baseURL, params) {
  return request({
    baseURL,
    url:'/v1/merchants/system/app/module',
    method:'get',
    params
  })
}

/*
   B,P端cms获取被排除的菜单项
 */
export function getMenuInfo() {
  return request({
    url: '/v2/merchant_exclude_menu',
    method: 'GET',
  })
}
// 图片上传OSS验证
export function getOssConfig(url, params) {
  return request({
    url: url + '/v2/ossUploadAuth',
    method: 'GET',
    params
  })
}

// 图片上传
export function uploader(url, data, header = {'Content-Type': 'multipart/form-data'}) {
  // if (!watchUpload) {
  //   watchUpload = function (v) {
  //     // // // console.log('ddd');
  //     // // // console.log(v);
  //   }
  // }
  return request({
    url,
    method: 'post',
    data,
    header,
    timeout: 6000000
  })
}
