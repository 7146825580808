/**
 * 数组元素交换位置
 * @param {array} arr 数组
 * @param {number} index1 添加项目的位置
 * @param {number} index2 删除项目的位置
 * index1和index2分别是两个数组的索引值，即是两个要交换元素位置的索引值，如1，5就是数组中下标为1和5的两个元素交换位置
 */
export function swapArray(arr, index1, index2) {
  arr[index1] = arr.splice(index2, 1, arr[index1])[0];
  return arr;
}


/*
 * cookie设置
 * */
export const cookier = {
  set: function (key, value, hours, domain) {
    let cookieStr = '';
    cookieStr = `${key}=${value};`;
    // console.log('hours',hours)
    // console.log('domain',domain)

    if (hours) {
      let exp = new Date();
      exp.setTime(exp.getTime() + hours * 60 * 60 * 1000);
      cookieStr += `expires=${exp.toGMTString()};`;
      // console.log('cmsSet document.cookie续期hours', cookieStr)
    }
    if (domain) {
      cookieStr += `domain=${domain};`;
      // console.log('cmsSet document.cookie续期domain', cookieStr)

    }
    document.cookie = cookieStr + 'path=/';
  },
  get: function (name) {
    let reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)', 'g');
    if (document.cookie.match(reg) && document.cookie.match(reg).length) {
      // 匹配cookie（若有多个，匹配最后即最新的一个）
      const {
        length
      } = document.cookie.match(reg);
      const matchStr = document.cookie.match(reg)[length - 1]
      if (matchStr) {
        let res = matchStr.match(/=([^;]*)(;|$)/);
        return res[1];
      } else {
        return null;
      }
    } else {
      return null;
    }
  },
  //删除cookie
  del: function (name) {
    let cval = this.get(name);
    const domain = window.location.origin.substring(window.location.origin.indexOf('.'));
    const ENV_TOP_DOMAIN = process.env.VUE_APP_TOP_DOMAIN;
    if (cval != null) {
      this.set(name, '', -1, ENV_TOP_DOMAIN || domain);
    }
  }
};

/*
 * 加密解密
 * */

export const Cryp = {
  set(word, keyStr) {
    keyStr = keyStr ? keyStr : 'abcde123ijklmnop'; //16位的密钥，自己定义，和下面的密钥要相同
    var key = CryptoJS.enc.Utf8.parse(keyStr);
    var srcs = CryptoJS.enc.Utf8.parse(word);
    var encrypted = CryptoJS.AES.encrypt(srcs, key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.toString();
  },
  //解密
  get(word, keyStr) {
    keyStr = keyStr ? keyStr : 'abcde123ijklmnop';
    var key = CryptoJS.enc.Utf8.parse(keyStr);
    var decrypt = CryptoJS.AES.decrypt(word, key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    return CryptoJS.enc.Utf8.stringify(decrypt).toString();
  }
};

/*
 * 转换文件大小
 * */
export function filterSize(b) {
  b = parseFloat(b);
  if (!b) {
    return '0B';
  }
  if (b < 1024) {
    return b + 'B';
  }
  const kb = (b / 1024).toFixed(2);
  if (kb < 1024) {
    return kb + 'KB';
  }
  const m = (kb / 1024).toFixed(2);
  if (m > 1024) {
    const g = (m / 1024).toFixed(2);
    return g + 'G';
  } else {
    return m + 'M';
  }
}

export function debounce(fn, delay) {
  let args = arguments,
    context = this,
    timer = null;

  return function () {
    if (timer) {
      clearTimeout(timer);

      timer = setTimeout(function () {
        fn.apply(context, args);
      }, delay);
    } else {
      timer = setTimeout(function () {
        fn.apply(context, args);
      }, delay);
    }
  }
}

/**
 * 本算法来源于简书开源代码，详见：https://www.jianshu.com/p/fdbf293d0a85
 * 全局唯一标识符（uuid，Globally Unique Identifier）,也称作 uuid(Universally Unique IDentifier) 
 * 一般用于多个组件之间,给它一个唯一的标识符,或者v-for循环的时候,如果使用数组的index可能会导致更新列表出现问题
 * 最可能的情况是左滑删除item或者对某条信息流"不喜欢"并去掉它的时候,会导致组件内的数据可能出现错乱
 * v-for的时候,推荐使用后端返回的id而不是循环的index
 * @param {Number} len uuid的长度
 * @param {Boolean} firstU 将返回的首字母置为"u"
 * @param {Nubmer} radix 生成uuid的基数(意味着返回的字符串都是这个基数),2-二进制,8-八进制,10-十进制,16-十六进制
 */
export function guid(len = 32, firstU = true, radix = null) {
	let chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
	let uuid = [];
	radix = radix || chars.length;

	if (len) {
		// 如果指定uuid长度,只是取随机的字符,0|x为位运算,能去掉x的小数位,返回整数位
		for (let i = 0; i < len; i++) uuid[i] = chars[0 | Math.random() * radix];
	} else {
		let r;
		// rfc4122标准要求返回的uuid中,某些位为固定的字符
		uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
		uuid[14] = '4';

		for (let i = 0; i < 36; i++) {
			if (!uuid[i]) {
				r = 0 | Math.random() * 16;
				uuid[i] = chars[(i == 19) ? (r & 0x3) | 0x8 : r];
			}
		}
	}
	// 移除第一个字符,并用u替代,因为第一个字符为数值时,该guuid不能用作id或者class
	if (firstU) {
		uuid.shift();
		return 'u' + uuid.join('');
	} else {
		return uuid.join('');
	}
}
