// import {
//     stat
// } from "fs";

// 用户
const state = {
  commentNum: {
    total_num: 0, // 评论最新总条数
    comment_switch:0 //1代表开启，0代表关闭

  },
};

const getters = {
  totalNum: state => state.commentNum.total_num,
  commentSwitchb : state => state.commentNum.comment_switch
}

const mutations = {
  newestTotalSet(state, flag) {
    console.log('flagflagflagflag',flag)
    state.commentNum.total_num = flag
    console.log('state.commentNum.sumTotal',state.commentNum)
  },
  commentStatus(state, flag){
    state.commentNum.comment_switch = flag
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations
}
