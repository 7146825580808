<template>
    <div class="functional-select-wrapper" @click.stop="multipleFocus()">
        <label class="display-container multiple-select-container clearfix"
               v-bind:class="(show)?'single-selected-focus':''">
            <p v-show="selectedList.length == 0 ">
                <span v-if="originOptions.length != 0 ">请选择</span>
                <span v-else>没有选项</span>
            </p>
            <p class="multiple-selected-item" v-for="item in selectedList" track-by="$index">{{ item.name }}<i
                    @click.stop.prevent="multipleRemove(item.id)">×</i></p>
            <i class="drop" :class="(show)?'drop-up':''">▼</i>
        </label>

        <div class="options-container" v-show="show">
            <div class="search-container">
                <input placeholder="search here" class="search-input" v-model="search"
                       v-on:keyup.enter="multipleSearch($event)"/>
            </div>
            <ul class="options-ul-list">
                <li v-show="displayOptions.length == 0">没有查询到数据</li>
                <li v-for="item in displayOptions" @click.stop.prevent="multipleSelect(item.id)"
                    :class=" selectedIdList.indexOf(item.id)!=-1?'selected':'' ">{{ item.name }}
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    export default {
        name: "select-vue-component",
        props: ['optionsdata', 'selecteddata'],
        data() {
            return {
                originOptions: [],
                displayOptions: [],
                show: false,
                search: '',
                selectedList: [],
                selectedIdList: []
            }
        },
        created() {
            this.originOptions = this.optionsdata;
            window.addEventListener('click', this.blur)
        },
        watch: {
            optionsdata(val, oldVal) {
                // // console.log('option old: ' + JSON.stringify(oldVal))
                // // console.log('option new: ' + JSON.stringify(val))
                this.show = false;
                this.originOptions = val;
            },
            selecteddata(val, oldVal) {
                // // console.log('selected old: ' + JSON.stringify(oldVal))
                // // console.log('selected new: ' + JSON.stringify(val))
                this.selectedList = val;
                this.selectedIdList = [];
                // 赋值selectedList
                for (var i = 0; i < this.selectedList.length; i++) {
                    var item = this.selectedList[i];
                    this.selectedIdList.push(item.id);
                }
            }
        },
        methods: {
            multipleFocus() {
                console.log('selectedListselectedList', this.selectedList)
                if (this.selectedList.length !== 5) {
                    console.log('444444', this.show);
                    if (!this.show) {
                        document.body.click();
                        // console.log('multiple show');
                        this.show = true;
                        this.multipleSearch();
                        this.searchInputFocus();
                    } else {
                        this.blur();
                    }
                }

            },
            searchInputFocus() {
                var searchInput = this.$el.getElementsByClassName('search-input')[0];
                console.log('222222', searchInput);
                this.$nextTick(() => {
                    searchInput.focus();
                })

            },
            multipleSelect(id) {
                var mySelf = this;
                var displayOptions = mySelf.originOptions;
                console.log('mySelf.selectedList',);
                if (mySelf.selectedList.length < 3) {
                    var selectedList = mySelf.selectedList;
                    var selectedIdList = mySelf.selectedIdList;
                } else {
                    return;
                }

                //在原始数组里找 找到后1.添加到selectedList
                //若selectedIdList存在 则删除
                if (selectedIdList.indexOf(id) != -1) {
                    mySelf.multipleRemove(id);
                    return;
                }
                // console.log(22)
                for (var i = 0; i < displayOptions.length; i++) {
                    var item = displayOptions[i]
                    if (item.id == id) {
                        selectedList.push(item);
                        selectedIdList.push(id);
                        mySelf.multipleInitSearch();
                        mySelf.multipleSearch();
                        mySelf.dispatchData();
                        mySelf.searchInputFocus();
                    }
                }

            },
            dispatchData() {
                // console.log(33)
                // // console.log('派发！！');
                // console.log('现在选中的是:', this.selectedList);
                this.$emit('selected', this.selectedList);
            },
            multipleRemove(id) {
                // console.log('删除！' + id)
                var mySelf = this;
                var selectedList = mySelf.selectedList;
                var selectedIdList = mySelf.selectedIdList;
                for (var i = 0; i < selectedList.length; i++) {
                    var item = selectedList[i]
                    if (item.id == id) {
                        //1.从selectedList中删除 2.从selectedIdList中删除
                        selectedList.splice(i, 1);
                        var index = selectedIdList.indexOf(item.id)
                        selectedIdList.splice(index, 1)
                        mySelf.multipleInitSearch();
                        mySelf.multipleSearch();
                        mySelf.dispatchData();
                        mySelf.searchInputFocus();
                        return;
                    }
                }
                this.selectedList = selectedList;
                this.selectedIdList = selectedIdList;
            },
            multipleSearch(event) {
                var mySelf = this;
                var search = mySelf.search;
                var REG_RULE = new RegExp(search, "i") //根据用户输入值做正则
                // // console.log(REG_RULE)
                //inputDom.style.width = (search.length*0.6) + 'em'
                var originOptions = mySelf.originOptions;
                var displayOptions = mySelf.displayOptions;
                // console.log(displayOptions)

                // 通过回车键 添加
                if (event && event.keyCode == 13 && search != '') {
                    // console.log('回车！');
                    console.log('通过回车添加的值' + search);
                    for (var i = 0; i < originOptions.length; i++) {
                        var item = originOptions[i]
                        if (item.name == search) {
                            mySelf.multipleSelect(item.id);
                            return;
                        } else if (i == (originOptions.length - 1)) {
                            // alert('不存在的选项！');
                            // 没有匹配任何选项，手动添加
                            this.selectedList.length < 3 && this.$emit('addtag', search, Math.random());
                            return;
                        }
                    }
                    if (originOptions.length == 0) {
                        this.selectedList.length < 3 && this.$emit('addtag', search, Math.random());
                        return;
                    }
                }

                //将展示列表置空 然后用正则去原始列表中匹配
                mySelf.displayOptions = [];
                //正则表达 匹配搜索字符
                for (var i = 0; i < originOptions.length; i++) {
                    var item = originOptions[i]
                    if (REG_RULE.test(item.name)) {
                        mySelf.displayOptions.push(item)
                        // // console.log(JSON.stringify(item))
                    }
                }
                // console.log('1111______', mySelf.displayOptions)
            },
            multipleInitSearch() {
                var mySelf = this;
                //重置搜索框 1.清空搜索数据 2.下拉框展示全量
                mySelf.search = '';
            },
            blur() {
                // console.log('hide multiple！！')
                this.show = false;
                this.search = '';
            }

        },
    }
</script>

<style scoped>

</style>
