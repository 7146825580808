// import {
//     stat
// } from "fs";

// 用户
import {getConSoleLog} from "../../util/util";

const state = {
    auditNum: {
        total_num: 0, //未审核总数（音频+文章）
        article_num: 0, //未审核文章数
        audio_num: 0, //未审核音频数
        oss_host: '',
        video_num: 0,//未审核视频数
        vms_domain: '',//资源共享中心地址
        cms_domain: '',//cms地址
        app_id: '',
        scs_merchant_domain: '', //scs前端域名地址
        vms_api_domain: '',
        ucenter_api_domain: '',  //uncenter-i-b后端域名
        shop_api_domain:'',  //菱选好物后端域名
        shop_merchant_pc_domain:'', // 菱选好物前端域名
        cms_c_domian:'', // CMS C端前端域名
        asc_merchant_domain: '' // 活动中心前端域名
    },
    // 是否显示转音频功能
    voiceStatus: 0
};

const getters = {
    totalNum: state => state.auditNum.total_num,
    articleNum: state => state.auditNum.article_num,
    audioNum: state => state.auditNum.audio_num,
    videoNum: state => state.auditNum.video_num,
    specialNum: state => state.auditNum.special_num,
    vmsDomain: state => state.auditNum.vms_domain,
    cmsDomain: state => state.auditNum.cms_domain,
    vmsAppid: state => state.auditNum.app_id,
    ossHost:state => state.auditNum.oss_host,
    scsDomian: state => state.auditNum.scs_merchant_domain,
    vmsApi: state => state.auditNum.vms_api_domain,
    ucenteDomain: state => state.auditNum.ucenter_api_domain,
    shopApi : state => state.auditNum.shop_api_domain,
    shopDomian : state => state.auditNum.shop_merchant_pc_domain,
    ascMerchantDomain : state => state.auditNum.asc_merchant_domain,
}

const mutations = {
    setAuditNum(state, flag) {
        state.auditNum = flag.auditNum;
        // state.auditNum.total_num=flag.auditNum.total_num?flag.auditNum.total_num:0;
        // state.auditNum.article_num=flag.auditNum.article_num?flag.auditNum.article_num:0;
        // state.auditNum.audio_num=flag.auditNum.audio_num?flag.auditNum.audio_num:0;
        // state.auditNum.video_num=flag.auditNum.video_num?flag.auditNum.video_num:0;
        // state.auditNum.vms_domain=flag.auditNum.vms_domain?flag.auditNum.vms_domain:'';
        // state.auditNum.cms_domain=flag.auditNum.cms_domain?flag.auditNum.cms_domain:'';
        // state.auditNum.app_id=flag.auditNum.app_id?flag.auditNum.app_id:0;

    },
    setArticleNum(state, flag) {
        state.auditNum.article_num = flag.num;
    },
    setAudioNum(state, flag) {
        state.auditNum.audio_num = flag.num;
    },
    setVideoNum(state, flag) {
        state.auditNum.video_num = flag.num;
    },
    setVmsDomain(state, flag) {
        state.auditNum.vms_domain = flag.domain;
    },
    setCmsDomain(state, flag) {
        state.auditNum.cms_domain = flag.domain;
    },
    setVoiceStatus(state, flag) {
        state.voiceStatus = flag;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations
}
