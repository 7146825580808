const smartAssistant = () => import('@/views/serve-manage/smart-assistant');

// 党建
const partyBuilding =()=>import('@/views/serve-manage/party-building/party-building');
const partyNewsEidt =()=>import('@/views/serve-manage/party-building/party-news-edit');
const partyServeEidt =()=>import('@/views/serve-manage/party-building/party-serve-edit');
const partyMatrixEidt =()=>import('@/views/serve-manage/party-building/party-matrix-edit');

// 政务
const goverAffairs =()=>import('@/views/serve-manage/gover-affairs/gover-affairs');
const goverOrgEdit =()=>import('@/views/serve-manage/gover-affairs/gover-org-edit');
const goverOrgGuide =()=>import('@/views/serve-manage/gover-affairs/org-guide');
const goverOrgGuideEdit =()=>import('@/views/serve-manage/gover-affairs/org-guide-edit');
const goverNewsEdit =()=>import('@/views/serve-manage/gover-affairs/gover-news-edit');
const goverServeEdit =()=>import('@/views/serve-manage/gover-affairs/gover-serve-edit');
const goverInteractEdit =()=>import('@/views/serve-manage/gover-affairs/gover-interact-edit');

// 生活服务
const lifeServe = () => import('@/views/serve-manage/life-serve/life-serve');
const allServe = () => import('@/views/serve-manage/life-serve/all-serve');
const localServe = () => import('@/views/serve-manage/life-serve/local-serve');

export default [
    // {
    //     path: '/serve-manage',
    //     name: '服务管理',
    //     component: smartAssistant,
    //     meta: {
    //       id: '11',
    //       openMenuId: 1,
    //       channelType: 1
    //     }
    // },
    {
      path: '/life-serve',
      name: '生活服务',
      component: lifeServe,
      meta: {
        id: '11'
      }
    },
    {
      path: '/all-serve',
      name: '通用服务新建',
      component: allServe,
      meta: {
        id: '11',
      }
    },
    {
      path: '/local-serve',
      name: '本地服务新建',
      component: localServe,
      meta: {
        id: '11',
      }
    },
    // 党建
    {
      path: '/party-building',
      name: '党建服务',
      component: partyBuilding,
      meta: {
        id: '13'
      }
  },
  {
      path: '/party-news-add',
      name: '资讯栏目配置——新建栏目',
      component: partyNewsEidt,
      meta: {
        id:'13'
      }
  },
  {
      path: '/party-news-edit/:id',
      name: '资讯栏目配置——编辑栏目',
      component: partyNewsEidt,
      meta: {
        id: '13'
      }
  },
  {
      path: '/party-serve-add',
      name: '服务管理——新建服务',
      component: partyServeEidt,
      meta: {
        id: '13'
      }
  },
  {
      path: '/party-serve-edit/:id',
      name: '服务管理——编辑服务',
      component: partyServeEidt,
      meta: {
        id:'13'
      }
  },
  {
      path: '/party-matrix-add',
      name: '微信矩阵管理——新建微信矩阵',
      component: partyMatrixEidt,
      meta: {
        id:'13'
      }
  },
  {
      path: '/party-matrix-edit/:id',
      name: '微信矩阵管理——编辑微信矩阵',
      component: partyMatrixEidt,
      meta: {
        id:'13'
      }
  },
  // 政务
  {
      path: '/gover-affairs',
      name: '政务服务',
      component: goverAffairs,
      meta: {
        id:'12'
      }
  },
  {
      path: '/gover-news-add',
      name: '新建资讯栏目',
      component: goverNewsEdit,
      meta: {
        id:'12'
      }
  },
  {
      path: '/gover-news-edit/:id',
      name: '编辑资讯栏目',
      component: goverNewsEdit,
      meta: {
        id:'12'
      }
  },
  {
      path: '/gover-org-add',
      name: '新建机构',
      component: goverOrgEdit,
      meta: {
        id:'12'
      }
  },
  {
      path: '/gover-org-edit/:id',
      name: '编辑机构',
      component: goverOrgEdit,
      meta: {
        id:'12'
      }
  },
  /*{
      path: '/org-guide/:orgId',
      name: '办事指南',
      component: goverOrgGuide,
      meta: {
        id:'12'
      }
  },*/
  {
      path: '/guide-add',
      name: '新建办事指南',
      component: goverOrgGuideEdit,
      meta: {
        id:'12'
      }
  },
  {
      path: '/guide-edit/:id',
      name: '编辑办事指南',
      component: goverOrgGuideEdit,
      meta: {
        id:'12'
      }
  },
  {
    path: '/gover-serve-add',
    name: '新建服务网点',
    component: goverServeEdit,
    meta: {
      id:'12'
    }
  },
  {
    path: '/gover-serve-edit/:id',
    name: '编辑服务网点',
    component: goverServeEdit,
    meta: {
      id:'12'
    }
  },
  {
    path: '/gover-interact-edit/:id',
    name: '建言资政详情',
    component: goverInteractEdit,
    meta: {
      id:'12'
    }
  },
]