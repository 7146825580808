import mAxios from "../util/axios";
import {cookier} from "../util/index";

/*
* 登录
* */
export function apiLogin(parmas) {
  return mAxios.post(
    '/v1/login',
    JSON.stringify(parmas)
  );
}

/*
* 登出
* */
export function apiLogout() {
  return mAxios.get(encodeURI(`/v1/loginOut`));
}

/*
* 验证手机是否存在于系统
* */
export function valiPhone(phone) {
  return mAxios.get(encodeURI(`/v1/phone/${phone}`));
}

/*
* 发送验证码
* */
export function
sendCode(query = '') {
  return mAxios.get(encodeURI(`/v1/sendCode?${query}`));
}

/*
* 修改密码
* */
export function editPassword(parmas) {
  return mAxios.put(
    '/v1/password',
    JSON.stringify(parmas)
  );
}

/*
* 用户信息(sso登录用)
* */
export function getUserInfo() {
  mAxios.defaults.headers.common['Authorization'] = cookier.get('Authorization');
  return mAxios.get(encodeURI(`/v1/merchant/user`));
}

/*
* 子系统列表
* */
export function getUserApps() {
  // mAxios.defaults.headers.common['Authorization'] = cookier.get('Authorization');
  return mAxios.get(encodeURI(`/v1/merchant/user/apps`));
}

/*
* 个人信息（用户中心用）
* */
export function getPersonalInfo() {
  return mAxios.get(encodeURI(`/v1/merchant/user`));
}

/*
* 修改个人信息（用户中心用）
* */
export function editPersonalInfo(parmas) {
  return mAxios.put(
    '/v1/merchant/user',
    JSON.stringify(parmas)
  );
}

/*
* 获取验证码
* */
export function getCode(query = '') {
  return mAxios.get(encodeURI(`/v1/merchant/phone_captcha?${query}`));
}

/*
* 验证验证码
* */
export function valiCode(parmas) {
  return mAxios.put(
    '/v1/merchant/phone_captcha',
    JSON.stringify(parmas)
  );
}

/*
* 修改手机号
* */
export function editUserPhone(parmas) {
  return mAxios.put(
    '/v1/merchant/user/phone',
    JSON.stringify(parmas)
  );
}