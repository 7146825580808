import store from '../store/index';
import router from '../router/index';
import {cookier} from "./index";
import {Notification} from 'element-ui';
/*
* HTTP响应拦截
* */
export function resInterceptors(response) {
  const auth = cookier.get('Authorization');

  if (!response.data) {
    // console.log(response);
    let domain = window.location.origin.substring(window.location.origin.indexOf('.'))
    const ENV_TOP_DOMAIN = process.env.VUE_APP_TOP_DOMAIN;
    cookier.set('Authorization', auth, 2, ENV_TOP_DOMAIN || domain);
    return response;
  }
  // response.data.code = '303001';
  // 303001  =>  授权失败 || 没有授权
  // 302001  =>  权限不足
  // console.log(response.data);
  // console.log(router.currentRoute)
  // if(router.currentRoute.path !== '/login' && router.currentRoute.path !== '/') {
  //   console.log(111)
  // }
  if (response.data.code === 202004) {
    // console.log(response);
    cookier.set('logoutPath', response.data.data + '/login');
    // debugger
    // localStorage.setItem('needLogout', '1');
    store.commit('logout', { focus: true }, { root: true });
    return Promise.reject('用户不存在，请重新登录');
  }
  if (response.data.code == '303001') {
    console.log(response)
    cookier.set('logoutPath', response.data.data + '/login');
    // debugger
    // localStorage.setItem('needLogout', '1');
    store.commit('logout', {focus: true}, {root: true});
    return Promise.reject('您未获得授权，请重新登录');
  }
  if (response.data.code == '302001') {
    console.log(response)
    cookier.set('logoutPath', response.data.data + '/login');
    Notification.info({
        message: `${response.data.msg}`
    });
    // debugger
    // store.commit('logout', {focus: true}, {root: true});
    return Promise.reject('用户权限不足，请更换账号登录');
    // alert('11')
    // return router.push('/no-access');
  }
  let domain = window.location.origin.substring(window.location.origin.indexOf('.'))
  const ENV_TOP_DOMAIN = process.env.VUE_APP_TOP_DOMAIN;
  cookier.set('Authorization', auth, 2, ENV_TOP_DOMAIN || domain);
  return response.data;
}

// // 请求拦截，在APP.vue里设置（需要获得权限）
export function reqInterceptors(config) {
  config.headers['Authorization'] = cookier.get('Authorization');
  return config;
}
