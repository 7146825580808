import Vue from 'vue';
import Router from 'vue-router';
import {
    cookier
} from "../util/index";
import {
    getUserInfo
} from '../api/user';
import store from '../store/index';
import mAxios from '../util/axios';
import {Notification} from 'element-ui';

// 唐星
const Index = () => import('../views/index');
const Setting = () => import('../views/setting/setting');
const Auth = () => import('../views/setting/auth');
const Radio = () => import('../views/radio/radio');
const RadioEdit = () => import('../views/radio/radio-edit');
const TV = () => import('../views/tv/television');
const TVEdit = () => import('../views/tv/television-edit');

// 吴志远路由
const auditManage = () => import('../views/audit-manage/audit-manage');
const auditAudioEdit = () => import('../views/audit-manage/audio-edit');
const auditVideoEdit = () => import('../views/audit-manage/video-edit');
const auditVideosEdit = () => import('../views/audit-manage/videos-edit');
const auditArticleEdit = () => import('../views/audit-manage/article-edit');
const auditSeminarEdit = () => import('../views/audit-manage/seminar-edit');
const auditGalleryEdit = () => import('../views/audit-manage/gallery-edit');
const auditShortVideoEdit = () => import('../views/audit-manage/short-video-edit');
const comment = () => import('../views/comment/comment');
const feedback = () => import('../views/feedback/feedback');
// 徐敏内容管理
const contentManage = () => import('../views/content-manage/content-manage');
// const ceshi = () => import('../views/content-manage/ceshi');
const audioEdit = () => import('../views/content-manage/audio-edit');
const articleEdit = () => import('../views/content-manage/article-edit');
const ablumEdit = () => import('../views/content-manage/album-edit');
const ablumLook = () => import('../views/content-manage/album-look');
const voideEdit = () => import('../views/content-manage/voide-edit');
const seminarEdit = () => import('../views/content-manage/seminar-edit');
const seminarLook = () => import('../views/content-manage/seminar-look');
const galleryEdit = () => import('../views/content-manage/gallery-edit');
const galleryLook = () => import('../views/content-manage/gallery-look');
const shortVideoEdit = () => import('../views/content-manage/short-video-edit');
// 徐敏发内容
const audioLook = () => import('../views/content-manage/audio-look');
const voideLook = () => import('../views/content-manage/voide-look');
const articleLook = () => import('../views/content-manage/article-look');
const noAccess = () => import('../views/access/no-access')
const shortVideoLook = () => import('../views/content-manage/short-video-look');

//袁凯凡路由
const reviewProcess = () => import('../views/audit-manage/review-process');
const FansDraw = () => import('../views/analyse/fans-draw')
const ReadAnalyse = () => import('../views/analyse/read-analyse')
const CommentAnalyse = () => import('../views/analyse/comment-analyse')
const PauseAnalyse = () => import('../views/analyse/pause-analyse')
const SubscribeAnalyse = () => import('../views/analyse/subscribe-analyse')
const CollectAnalyse = () => import('../views/analyse/collect-analyse')
const NumberCensus = () => import('../views/census/number-census')
const WebCensus = () => import('../views/census/web-census')
const UeditorComp = () => import('../views/ueditor-example')
const Builder = () => import('../views/builder/index')
const sourceSetting = () => import ('../views/setting/source-setting')
const sendContentCenter = () => import('../views/builder/send-content-center')
import routerW from './module/router-w';
import routerCivil from './module/router-civil';
import {getConSoleLog} from "../util/util";

let router = new Router({
    mode: 'history',
    routes: [{
        path: '/',
        component: Index,
        redirect: '/number-census',
        children: [
            // 唐星路由
            {
                path: '/ueditor',
                name: '百度编辑器',
                component: UeditorComp,
                meta: {
                    id: '99'
                }
            },
            {
                path: '/setting',
                name: '菜单设置',
                component: Setting,
                meta: {
                    id: '81'
                }
            },
            {
                path: '/auth',
                name: '内容权限',
                component: Auth,
                meta: {
                    id: '82'
                }
            },
            {
                path: '/source-setting',
                name: '系统来源',
                component: sourceSetting,
                meta: {
                    id: '83'
                }
            },
            {
                path: '/radio',
                name: '电台管理',
                component: Radio,
                meta: {
                    id: '3',
                    openMenuId: 1,
                    channelType: 1
                }
            },
            {
                path: '/radio-edit',
                name: '新增电台',
                component: RadioEdit,
                meta: {
                    id: '3',
                    openMenuId: 1,
                    channelType: 1
                }
            },
            {
                path: '/radio-edit/:id',
                name: '编辑电台',
                component: RadioEdit,
                meta: {
                    id: '3',
                    openMenuId: 1,
                    channelType: 1
                }
            },
            {
                path: '/tv',
                name: '电视管理',
                component: TV,
                meta: {
                    id: '4',
                    openMenuId: 1,
                    channelType: 2
                }
            },
            {
                path: '/tv-edit',
                name: '新增电视台',
                component: TVEdit,
                meta: {
                    id: '4',
                    openMenuId: 1,
                    channelType: 2
                }
            },
            {
                path: '/tv-edit/:id',
                name: '编辑电台',
                component: TVEdit,
                meta: {
                    id: '4',
                    openMenuId: 1,
                    channelType: 2
                }
            },
            // 吴志远路由
            {
                path: '/audit-manage',
                name: '审核管理',
                component: auditManage,
                meta: {
                    id: '5'
                }
            },
            {
                path: '/audit-audio-edit/:id',
                name: '音频审核',
                component: auditAudioEdit,
                meta: {
                    id: '5'
                }
            },
            {
                path: '/audit-video-edit/:id',
                name: '视频审核',
                component: auditVideoEdit,
                meta: {
                    id: '5'
                }
            },
            {
                path: '/audit-videos-edit/:id',
                name: '视频合集审核',
                component: auditVideosEdit,
                meta: {
                    id: '5'
                }
            },
            {
                path: '/audit-article-edit/:id',
                name: '文章审核',
                component: auditArticleEdit,
                meta: {
                    id: '5'
                }
            },
            {
                path: '/audit-seminar-edit/:id',
                name: '专题审核',
                component: auditSeminarEdit,
                meta: {
                    id: '5'
                }
            },
            {
                path: '/audit-gallery-edit/:id',
                name: '图集审核',
                component: auditGalleryEdit,
                meta: {
                    id: '5'
                }
            },
            {
                path: '/audit-shortVideo-edit/:id',
                name: '短视频审核',
                component: auditShortVideoEdit,
                meta: {
                    id: '5'
                }
            },
            {
                path: '/comment',
                name: '评论管理',
                component: comment,
                meta: {
                    id: '6',
                    openMenuId: 1,
                    channelType: 1
                }
            },
            // 徐敏路由-内容管理
            {
                path: '/content-manage',
                name: '内容管理',
                component: contentManage,
               meta:{
                    id:'2'
               }
            },
            {
                path: '/audio-edit/:id',
                name: '音频编辑',
                component: audioEdit,
                meta: {
                    id: '2'
                }
            },
            {
                path: '/article-edit/:id',
                name: '文章编辑',
                component: articleEdit,
                meta: {
                    id: '2'
                }
            },
            {
                path: '/ablum-edit/:id',
                name: '专辑编辑',
                component: ablumEdit,
                meta: {
                    id: '2'
                }
            },
            {
                path: '/ablum-look/:id',
                name: '专辑查看',
                component: ablumLook,
                meta: {
                    id: '2'
                }
            },
            {
                path: '/voide-edit/:id',
                name: '视频编辑',
                component: voideEdit,
                meta: {
                    id: '2'
                }
            },
            {
                path: '/seminar-edit/:id',
                name: '专题编辑',
                component: seminarEdit,
                meta: {
                    id: '2'
                }
            },
            {
                path: '/voide-look/:id',
                name: '视频查看',
                component: voideLook,
                meta: {
                    id: '2'
                }
            },
            {
                path: '/gallery-edit/:id',
                name: '图集编辑',
                component: galleryEdit,
                meta: {
                    id: '2'
                }
            },
            {
                path: '/gallery-look/:id',
                name: '图集查看',
                component: galleryLook,
                meta: {
                    id: '2'
                }
            },
            {
                path: '/short-video-edit/:id',
                name: '短视频编辑',
                component: shortVideoEdit,
                meta: {
                    id: '2'
                }
            },
            {
                path: '/short-video-look/:id',
                name: '短视频查看',
                component: shortVideoLook,
                meta: {
                    id: '2'
                }
            },

            // 徐敏路由-发内容
            {
                path: '/send-content',
                name: '发内容',
                component: Builder, //发内容
                 meta: {
                    id:'1'
                },
                redirect: '/send-content-center' //重定向至新版本发内容
            },
            {
                path: '/audio-look/:id',
                name: '音频查看',
                component: audioLook,
                meta: {
                    id: '1'
                }
            },
            {
                path: '/article-look/:id',
                name: '文章查看',
                component: articleLook,
                meta: {
                    id: '2'
                }
            },
            {
                path: '/seminar-look/:id',
                name: '专题查看',
                component: seminarLook,
                meta: {
                    id: '1'
                }
            },
            {
                path: '/review-process/:id',
                name: '审核流程',
                component: reviewProcess,
                meta: {
                    id: '5'
                }
            },
            {
                path: '/feedback',
                name: '意见与反馈',
                component: feedback,
                meta: {
                    id: '7'
                }
            },
            //ykf
            {
                path: "/number-census",
                name: '统计',
                component: NumberCensus,
                meta: {
                    id: '10'
                }
            },
            {
                path: "/web-census",
                name: '统计',
                component: WebCensus,
                meta: {
                    id: '10'
                }
            },
            {
                path: "/fans-draw",
                name: '粉丝概况',
                component: FansDraw,
                meta: {
                    id: '9'
                }
            },
            {
                path: "/read-analyse",
                name: '阅读量',
                component: ReadAnalyse,
                meta: {
                    id: '9'
                }
            },
            {
                path: "/comment-analyse",
                name: '评论分析',
                component: CommentAnalyse,
                meta: {
                    id: '9'
                }
            },
            {
                path: "/pause-analyse",
                name: '播放总量',
                component: PauseAnalyse,
                meta: {
                    id: '9'
                }
            },
            {
                path: "/subscribe-analyse",
                name: '订阅量',
                component: SubscribeAnalyse,
                meta: {
                    id: '9'
                }
            },
            {
                path: "/collect-analyse",
                name: '收藏量',
                component: CollectAnalyse,
                meta: {
                    id: '9'
                }
            },
            {
                path: '/no-access',
                name: '无权限',
                component: noAccess,
                meta: {
                    keepAlive: false,
                }
            },
            ...routerW,
            ...routerCivil
        ]
    },
        {
            path: '*',
            redirect: '/number-census'
        },
        // {
        //     path: '/builder',
        //     name: '发布中心',
        //     component: Builder,
        // },
        {
            path: '/send-content-center',
            name: '发布中心',
            component: sendContentCenter, //发内容
            meta: {
                id:'1'
            }
        },
    ]
});
const RADIO_ROUTE = ['radio', 'radio-edit'];
const TV_ROUTE = ['tv', 'tv-edit'];
const AUDIT_ROUTE = ['/audit-manage', '/audit-audio-edit', '/audit-article-edit', '/audit-video-edit'];
const SETTING_ROUTE = ['/setting', '/auth'];
let permissions = [];

//--------------xm
function isEnterSetting(path) {
    const settingRoutes = [
        '/content-manage', '/number-census', '/web-census', '/send-content','/builder', '/comment', '/feedback', '/setting', '/fans-draw', '/read-analyse', '/comment-analyse', '/pause-analyse',
        'subscribe-analyse', 'collect-analyse', '/audit-manage', '/life-serve', '/gover-affairs', '/part-building', '/radio', '/tv '
    ];
    return settingRoutes.includes(path)
}

function judgePath(permissions, arr) {
    let isHas = false;
    if (arr.length) {
        for (let i = 0; i < arr.length; i++) {
            let index = permissions.findIndex(ele => {
                return arr[i] == ele.real_name;
            });
            if (index != -1) {
                isHas = true;
                break;
            }
        }
        return isHas;
    } else {
        return true;
    }
}

function isCanEnter(path) {
    console.log('permissionsrwew :>> ', permissions);
    if (permissions && permissions.length) {
        let arr = [];
        switch (path) {
            case '/content-manage':
                // arr = ['b_cms_content_view', 'b_cms_content_all'];
                arr = ['b_cms_content'];
                break;
            case '/number-census':
            case '/web-census':
            case '/fans-draw':
            case '/read-analyse':
            case '/comment-analyse':
            case '/pause-analyse':
            case '/subscribe-analyse':
            case 'collect-analyse':
                arr = ['b_cms_statistc_all'];
                break;
            case '/send-content':
                // arr = ['b_cms_content_all'];
                arr = ['b_cms_createcontent'];
                break;
            case '/builder':
                arr = ['b_cms_createcontent'];
                break;
            case '/comment':
                arr = ['b_cms_comment_view', 'b_cms_comment_all'];
                break;
            case '/feedback':
                arr = ['b_cms_feedback_all'];
                break;
            case '/setting':
                // arr = ['b_cms_setting_all'];
                arr = ['b_cms_setting'];
                break;
            case '/audit-manage':
                // arr = ['b_cms_workflow_view', 'b_cms_workflow_all'];
                arr = ['b_cms_workflow_all'];
                break;
            case '/life-serve':
                arr = ['b_cms_life_view', 'b_cms_life_all'];
                break;
            case '/gover-affairs':
                arr = ['b_cms_government_view', 'b_cms_government_all'];
                break;
            case '/party-building':
                arr = ['b_cms_party_view', 'b_cms_party_all'];
                break;
            case '/radio':
                // arr = ['b_cms_rchannel_view', 'b_cms_rchannel_all'];
                arr = ['b_cms_channel_radios'];
                break;
            case '/tv':
                // arr = ['b_cms_tchannel_view', 'b_cms_tchannel_all'];
                arr = ['b_cms_channel_tv'];
                break;
            default:
                arr = [];
                break;
        }
        return judgePath(permissions, arr);
    } else {
        return false;
    }

}

//-------------xm
function isInRoute(arr, route) {
    const index = arr.findIndex(item => {
        return route.path.includes(item);
    });
    return index > -1;
}

function canEnterRadio(merchant) {
    return merchant && merchant.type == 1 && merchant.media && (merchant.media.type == 1 || merchant.media.type == 4);
}

function canEnterTv(merchant) {
    return merchant && merchant.type == 1 && merchant.media && (merchant.media.type == 2 || merchant.media.type == 4);
}

function amINotAnchor(merchant) {
    return merchant && merchant.type != 11;
}

function amISuper(merchant_user) {
    return false;
}

router.beforeEach((to, from, next) => {
    var _this = this;
    const logoutPath = cookier.get('logoutPath');
    if (to.meta) {
        if (to.meta.id != 11) {
            localStorage.removeItem('sl_serve_tab');
        }
        if (to.meta.id != 12) {
            localStorage.removeItem('sl_gover_tab');
        }
        if (to.meta.id != 13) {
            localStorage.removeItem('sl_dj_tab');
        }
        if (to.meta.id != 2) {
            localStorage.removeItem('sl_cont_tab');
            localStorage.removeItem('current_page_size');
        }
    }
    if (to.path === '/') {
        // 登录页正常 next
        return next();
    }
    const tk = cookier.get('Authorization');
    if (!tk && logoutPath) {
        // 2、没有授权头，跳转登录
        return store.commit('logout', {}, {
            root: true
        });
        // next();
    } else if (!tk && !logoutPath) {
        next();
    } else {
        mAxios.defaults.headers.common['Authorization'] = tk;
        // 有授权头，判断用户信息
        let userInfo = store.getters['user/userInfo'];
        getUserInfo().then(res => {
            // console.log('获取用户信息');
            if (res.code == '100000') {
                cookier.set('logoutPath', res.data.domain + '/login');
                store.commit('user/setUserInfo', {
                    data: res.data
                });
                const enterRadio = canEnterRadio(res.data.merchant);
                const enterTv = canEnterTv(res.data.merchant);

                const notAnchor = amINotAnchor(res.data.merchant);
                sessionStorage.setItem('merchant_type', res.data.merchant.type);
                const isSuper = amISuper(res.data.merchant_user);

                // const isRouteInAudit = isInRoute(AUDIT_ROUTE, to);
                // const isRouteInSetting = isInRoute(SETTING_ROUTE, to);

                const isRouteInTv = isInRoute(TV_ROUTE, to);
                const isRouteInRadio = isInRoute(RADIO_ROUTE, to);
                // console.log(isRouteInSetting, isSuper, notAnchor);
                /*if ((!enterRadio && isRouteInRadio) || (!enterTv && isRouteInTv)) {
                    return next('/');
                }*/
                //____________________  xm开始
                permissions = res.data.permissions
                // 当permissions数组为空时则是超级管理员权限,并且遍历permissions是否有b_cms_XX,如果有则判断没有这默认为管理员权限
                let msg = '';
                if (isEnterSetting(to.path) && (!isCanEnter(to.path))) {
                    console.log('进来了 :>> ', );
                    // 不是超管并且进入了权限控制页面
                    switch (to.path) {
                        case '/content-manage':
                        case '/audio-edit':
                            msg = "内容管理";
                            break;
                        case '/number-census':
                        case '/web-census':
                        case '/fans-draw':
                        case '/read-analyse':
                        case '/comment-analyse':
                        case '/pause-analyse':
                        case '/subscribe-analyse':
                        case '/collect-analyse':
                            msg = "统计与数据分析";
                            break;
                        case '/send-content':
                            msg = "发内容";
                            break;
                        case '/builder':
                            msg = "发布中心";
                            break;
                        case '/comment':
                            msg = "评论管理";
                            break;
                        case '/feedback':
                            msg = "意见与反馈";
                            break;
                        case '/setting':
                            msg = "系统设置";
                            break;
                        case '/audit-manage':
                            msg = "审核管理";
                            break;
                        case '/life-serve':
                        case '/gover-affairs':
                        case '/party-building':
                            msg = "服务管理";
                            break;
                        case '/radio':
                            msg = "电台管理";
                            break;
                        case '/tv':
                            msg = "电视台管理";
                            break;
                    }
                    Notification.info({
                        title: '无权限',
                        message: `您没有进入${msg}模块的权限`
                    });
                    next({path: '/no-access'})
                } else {
                    return next();
                }
                // next();
            }
        })
        // if (userInfo.domain && logoutPath) {
        //   // // 设置用户信息
        //   // const enterRadio = canEnterRadio(userInfo.merchant);
        //   // const enterTv = canEnterTv(userInfo.merchant);
        //   //
        //   // const notAnchor = amINotAnchor(userInfo.merchant);
        //   // const isSuper = amISuper(userInfo.merchant_user);
        //   //
        //   // const isRouteInAudit = isInRoute(AUDIT_ROUTE, to);
        //   // const isRouteInSetting = isInRoute(SETTING_ROUTE, to);
        //   //
        //   // const isRouteInTv = isInRoute(TV_ROUTE, to);
        //   // const isRouteInRadio = isInRoute(RADIO_ROUTE, to);
        //   // if ((!enterRadio && isRouteInRadio) || (!enterTv && isRouteInTv) || (isRouteInAudit && !notAnchor) || (isRouteInSetting && !(isSuper && notAnchor))) {
        //   //   return next('/');
        //   // }
        //   // document.title = `${to.name}` || '蚂蚁FM';
        //   // return next();
        //   getUserInfo().then(res => {
        //     // console.log('获取用户信息');
        //     if (res.code == '100000') {
        //       cookier.set('logoutPath', res.data.domain + '/login');
        //       store.commit('user/setUserInfo', {
        //         data: res.data
        //       });
        //       const enterRadio = canEnterRadio(res.data.merchant);
        //       const enterTv = canEnterTv(res.data.merchant);
        //
        //       const notAnchor = amINotAnchor(res.data.merchant);
        //       const isSuper = amISuper(res.data.merchant_user);
        //
        //       const isRouteInAudit = isInRoute(AUDIT_ROUTE, to);
        //       const isRouteInSetting = isInRoute(SETTING_ROUTE, to);
        //
        //       const isRouteInTv = isInRoute(TV_ROUTE, to);
        //       const isRouteInRadio = isInRoute(RADIO_ROUTE, to);
        //       // console.log(isRouteInSetting, isSuper, notAnchor);
        //       if ((!enterRadio && isRouteInRadio) || (!enterTv && isRouteInTv) || (isRouteInAudit && !notAnchor) || (isRouteInSetting && !(isSuper && notAnchor))) {
        //         return next('/');
        //       }
        //       document.title = `${to.name}` || '蚂蚁FM';
        //       return next();
        //     }
        //   })
        // } else {
        //   // 4、没有用户信息 或 用户授权头与本地不相等
        //   getUserInfo().then(res => {
        //     // console.log('获取用户信息');
        //     if (res.code == '100000') {
        //       cookier.set('logoutPath', res.data.domain + '/login');
        //       store.commit('user/setUserInfo', {
        //         data: res.data
        //       });
        //       const enterRadio = canEnterRadio(res.data.merchant);
        //       const enterTv = canEnterTv(res.data.merchant);
        //
        //       const notAnchor = amINotAnchor(res.data.merchant);
        //       const isSuper = amISuper(res.data.merchant_user);
        //
        //       const isRouteInAudit = isInRoute(AUDIT_ROUTE, to);
        //       const isRouteInSetting = isInRoute(SETTING_ROUTE, to);
        //
        //       const isRouteInTv = isInRoute(TV_ROUTE, to);
        //       const isRouteInRadio = isInRoute(RADIO_ROUTE, to);
        //       // console.log(isRouteInSetting, isSuper, notAnchor);
        //       if ((!enterRadio && isRouteInRadio) || (!enterTv && isRouteInTv) || (isRouteInAudit && !notAnchor) || (isRouteInSetting && !(isSuper && notAnchor))) {
        //         return next('/');
        //       }
        //       document.title = `${to.name}` || '蚂蚁FM';
        //       return next();
        //     }
        //   })
        // }
    }
    if(!window.__POWERED_BY_QIANKUN__) {
        document.title=to.name||'蚂蚁FM'
    }
});

router.onError((error) => {
    const isChunkLoadFailed = error.message.indexOf('Loading chunk') > -1;
    const targetPath = router.history.pending.fullPath;
    if (isChunkLoadFailed) {
        router.replace(targetPath);
    }
});


Vue.use(Router);

export default router;
