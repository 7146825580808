// 用户
const state = {
  show: true,
  collapse: false
};

const mutations = {
  toggleCollapse(state, {collapse}) {
    state.collapse = collapse;
  },
  toggleShow(state, {show}) {
    state.show = show;
  }
};

export default {
  namespaced: true,
  state,
  mutations
}
